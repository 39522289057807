export default () => {
  const ElmMenuBtnTxt = document.querySelector('#btnHumbagerMenuTxt')
  const ElmMenuBtn = document.querySelector('#btnHumbagerMenu')
  const AttrMenuBtnClose = 'data-status'
  const ElmDrawerMenu = document.querySelector('#drawerMenu')
  const AttrDrawerMenu = 'data-visivle'
  const ElmDrawerMenu_a = document.querySelectorAll('#drawerMenu a')
  const Func = {
    show: function () {
      ElmMenuBtnTxt.textContent = 'CLOSE'
      ElmMenuBtn.setAttribute(AttrMenuBtnClose, 'close')
      ElmDrawerMenu.setAttribute(AttrDrawerMenu, 'show')
    },
    hide: function () {
      ElmMenuBtnTxt.textContent = 'MENU'
      ElmMenuBtn.setAttribute(AttrMenuBtnClose, 'normal')
      ElmDrawerMenu.setAttribute(AttrDrawerMenu, 'hide')
    },
  }
  ElmMenuBtn.addEventListener('click', (e) => {
    if (ElmMenuBtn.getAttribute(AttrMenuBtnClose) === 'normal') {
      Func.show()
    } else {
      Func.hide()
    }
  })
  for (let i = 0; i < ElmDrawerMenu_a.length; i++) {
    ElmDrawerMenu_a[i].addEventListener('click', (e) => {
      Func.hide()
    })
  }
}
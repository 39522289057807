export default () => {
  const Speed = 700
  const Ignore = '.no-smooth-scroll'
  const SmoothScrollElm = document.querySelectorAll('a[href^="#"]:not(' + Ignore + ')')
  const Easing = (t, b, c, d) => {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t + b
    return c / 2 * ((t -= 2) * t * t + 2) + b
  }
  const ScrollElm = () => {
    if ('scrollingElement' in document) return document.scrollingElement
    if (navigator.userAgent.indexOf('WebKit') != -1) return document.body
    return document.documentElement
  }

  Array.prototype.forEach.call(SmoothScrollElm, (_elm => {
    _elm.addEventListener('click', (e => {
      e.preventDefault()
      let targetElm = document.querySelector(_elm.getAttribute('href'))
      if (!targetElm) return
      let targetPos = targetElm.getBoundingClientRect().top
      let startTime = Date.now()
      let scrollFrom = ScrollElm().scrollTop

      // 固定ヘッダーの場合、その高さだけスクロール位置をズラす
      if (window.innerWidth > 980) {
        targetPos -= 99
      } else {
        targetPos -= 70
      }

      (function loop() {
        let currentTime = Date.now() - startTime
        if (currentTime < Speed) {
          scrollTo(0, Easing(currentTime, scrollFrom, targetPos, Speed))
          window.requestAnimationFrame(loop)
        } else {
          scrollTo(0, targetPos + scrollFrom)
        }
      })()
      return false
    }))
  }))
}
const AttrFlag = 'data-visivle'
const Func = {
  show: function (_elmId) {
    if (!!document.querySelector(`#${_elmId}`)) {
      document.querySelector(`#${_elmId}`).setAttribute(AttrFlag, 'show')
    }
  },
  hide: function (_elmId) {
    if (!!document.querySelector(`#${_elmId}`)) {
      document.querySelector(`#${_elmId}`).setAttribute(AttrFlag, 'hide')
    }
  },
}

export default {
  func: Func
}
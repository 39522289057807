const Attr = 'data-direction'
const Func = {
  top: function (_elmId) {
    if (!!document.querySelector(`#${_elmId}`)) {
      document.querySelector(`#${_elmId}`).setAttribute(Attr, 'top')
      document.querySelector(`#${_elmId} a`).setAttribute('href', '#pageUpper')
    }
  },
  bottom: function (_elmId) {
    if (!!document.querySelector(`#${_elmId}`)) {
      document.querySelector(`#${_elmId}`).setAttribute(Attr, 'bottom')
      document.querySelector(`#${_elmId} a`).setAttribute('href', '#footer')
    }
  },
}

export default {
  func: Func
}
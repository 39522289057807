/*
 * file import
 */
import getOffset_y from './getOffset_y';
import scrollVisivleElm from './scrollVisivleElm';
import scrollBtnChange from './scrollBtnChange';
import click_smoothScroll from './smoothScroll';
import click_humbagerMenu from './humbagerMenu';

/*
 * スクロール量に応じて実行
 */
window.addEventListener('scroll', () => {
  if (getOffset_y() >= document.documentElement.scrollHeight - window.innerHeight - 400) { // 最後のスクロール
    scrollVisivleElm.func.hide('recruitFixedBanner')
    scrollBtnChange.func.top('scrollBtn')
  } else if (getOffset_y() >= 400) { // 最初のスクロール
    scrollVisivleElm.func.show('recruitFixedBanner')
    scrollVisivleElm.func.show('scrollBtn')
    scrollBtnChange.func.bottom('scrollBtn')
  } else {
    scrollVisivleElm.func.show('recruitFixedBanner')
    scrollVisivleElm.func.hide('scrollBtn')
    scrollBtnChange.func.bottom('scrollBtn')
  }
})

/*
 * 要素クリック時に実行
 */
click_smoothScroll()
click_humbagerMenu()